import { useState, useCallback, useEffect, useRef } from 'react';
import { useDrag } from 'react-use-gesture';
export function useColumnDragState(fields, onTouched) {
    // wrap in ref to avoid re-triggering
    const onTouchedRef = useRef(onTouched);
    onTouchedRef.current = onTouched;
    const [dragState, setDragState] = useState(null);
    const [fieldAssignments, setFieldAssignments] = useState({});
    // make sure there are no extra fields
    useEffect(() => {
        const removedFieldNames = Object.keys(fieldAssignments).filter((existingFieldName) => !fields.some((field) => field.name === existingFieldName));
        if (removedFieldNames.length > 0) {
            // @todo put everything inside this setter
            setFieldAssignments((prev) => {
                const copy = Object.assign({}, prev);
                removedFieldNames.forEach((fieldName) => {
                    delete copy[fieldName];
                });
                return copy;
            });
        }
    }, [fields, fieldAssignments]);
    const internalAssignHandler = useCallback((column, fieldName) => {
        setFieldAssignments((prevAssignments) => {
            const copy = Object.assign({}, prevAssignments);
            // ensure dropped column does not show up elsewhere
            Object.keys(prevAssignments).forEach((name) => {
                if (copy[name] === column.index) {
                    delete copy[name];
                }
            });
            // set new field column
            if (fieldName !== null) {
                copy[fieldName] = column.index;
            }
            return copy;
        });
        // mark for validation display
        if (fieldName) {
            onTouchedRef.current(fieldName);
        }
    }, []);
    const bindDrag = useDrag(({ first, last, event, xy, args }) => {
        if (first && event) {
            event.preventDefault();
            const [column, startFieldName] = args;
            setDragState({
                pointerStartInfo: {
                    initialXY: xy,
                    initialWidth: event.currentTarget instanceof HTMLElement
                        ? event.currentTarget.offsetWidth
                        : 0
                },
                column,
                dropFieldName: startFieldName !== undefined ? startFieldName : null,
                updateListener: null
            });
        }
        else if (last) {
            setDragState(null);
            if (dragState) {
                internalAssignHandler(dragState.column, dragState.dropFieldName);
            }
        }
        // @todo figure out a cleaner event stream solution
        if (dragState && dragState.updateListener) {
            dragState.updateListener(xy);
        }
    }, {});
    const columnSelectHandler = useCallback((column) => {
        setDragState((prev) => {
            // toggle off if needed
            if (prev && prev.column === column) {
                return null;
            }
            return {
                pointerStartInfo: null,
                column,
                dropFieldName: null,
                updateListener: null
            };
        });
    }, []);
    const dragHoverHandler = useCallback((fieldName, isOn) => {
        setDragState((prev) => {
            if (!prev) {
                return prev;
            }
            if (isOn) {
                // set the new drop target
                return Object.assign(Object.assign({}, prev), { dropFieldName: fieldName });
            }
            else if (prev.dropFieldName === fieldName) {
                // clear drop target if we are still the current one
                return Object.assign(Object.assign({}, prev), { dropFieldName: null });
            }
            // no changes by default
            return prev;
        });
    }, []);
    const assignHandler = useCallback((fieldName) => {
        // clear active drag state
        setDragState(null);
        if (dragState) {
            internalAssignHandler(dragState.column, fieldName);
        }
    }, [internalAssignHandler, dragState]);
    const unassignHandler = useCallback((column) => {
        setFieldAssignments((prev) => {
            const assignedFieldName = Object.keys(prev).find((fieldName) => prev[fieldName] === column.index);
            if (assignedFieldName === undefined) {
                return prev;
            }
            const copy = Object.assign({}, prev);
            delete copy[assignedFieldName];
            return copy;
        });
    }, []);
    return {
        fieldAssignments,
        dragState,
        dragEventBinder: bindDrag,
        dragHoverHandler,
        columnSelectHandler,
        assignHandler,
        unassignHandler
    };
}
