var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, useCallback, useEffect, useContext } from 'react';
import { FileSelector } from './FileSelector';
import { FormatPreview } from './FormatPreview';
import { ColumnPicker } from './ColumnPicker';
import { ProgressDisplay } from './ProgressDisplay';
import './Importer.scss';
const FieldDefinitionContext = React.createContext(null);
export const ImporterField = ({ name, label, optional }) => {
    const fieldSetter = useContext(FieldDefinitionContext);
    // update central list as needed
    useEffect(() => {
        if (!fieldSetter) {
            console.error('importer field must be a child of importer'); // @todo
            return;
        }
        fieldSetter((prev) => {
            const newField = { name, label, isOptional: !!optional };
            const copy = [...prev];
            const existingIndex = copy.findIndex((item) => item.name === name);
            // preserve existing array position if possible
            if (existingIndex === -1) {
                copy.push(newField);
            }
            else {
                copy[existingIndex] = newField;
            }
            return copy;
        });
    }, [fieldSetter, name, label, optional]);
    return null;
};
function ImporterCore(_a) {
    var { fields, chunkSize, assumeNoHeaders, restartable, processChunk, onStart, onComplete, onClose } = _a, customPapaParseConfig = __rest(_a, ["fields", "chunkSize", "assumeNoHeaders", "restartable", "processChunk", "onStart", "onComplete", "onClose"]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [editFormat, setEditFormat] = useState(false);
    const [fieldAssignments, setFieldAssignments] = useState(null);
    const fileHandler = useCallback((file) => {
        setSelectedFile(file);
    }, []);
    if (selectedFile === null) {
        return React.createElement(FileSelector, { onSelected: fileHandler });
    }
    if (preview === null || editFormat) {
        return (React.createElement(FormatPreview, { customConfig: customPapaParseConfig, file: selectedFile, assumeNoHeaders: assumeNoHeaders, currentPreview: preview, onAccept: (parsedPreview) => {
                setPreview(parsedPreview);
                setEditFormat(false);
            }, onCancel: () => {
                setSelectedFile(null);
            } }));
    }
    if (fieldAssignments === null) {
        return (React.createElement(ColumnPicker, { fields: fields, preview: preview, onAccept: (assignments) => {
                setFieldAssignments(assignments);
            }, onCancel: () => {
                // keep existing preview data
                setEditFormat(true);
            } }));
    }
    return (React.createElement(ProgressDisplay, { preview: preview, fieldAssignments: fieldAssignments, chunkSize: chunkSize, processChunk: processChunk, onStart: onStart, onRestart: restartable
            ? () => {
                // reset all state
                setSelectedFile(null);
                setPreview(null); // not bothering with editFormat flag
                setFieldAssignments(null);
            }
            : undefined, onComplete: onComplete, onClose: onClose }));
}
export function Importer(_a) {
    var { children } = _a, props = __rest(_a, ["children"]);
    const [fields, setFields] = useState([]);
    return (React.createElement("div", { className: "CSVImporter_Importer" },
        React.createElement(ImporterCore, Object.assign({ fields: fields }, props)),
        React.createElement(FieldDefinitionContext.Provider, { value: setFields }, children)));
}
